import { makeStyles, Theme } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import { ReactNode } from 'react'

import {
  convertTimeStampToFormatDate,
  convertTimeStampZToFormatDate,
  isDateValid,
} from '../../shared/utils/functions'
import CircularComponent from '../components/CircularComponent'

export enum HideableDataGridColumns {
  question = 'question',
  updatedAt = 'updatedAt',
  claimStatus = 'claimStatus',
}

const useStyles = makeStyles((_theme: Theme) => ({
  visibilityHidden: {
    visibility: 'hidden',
  },
}))

const useDataGridColumns = (
  columnsToHide: HideableDataGridColumns[], //keyof typeof HideableDataGridColumns[], //,
  renderActionsCell: ((params: GridRenderCellParams) => ReactNode) | undefined,
  userdata: any
): { columns: GridColumns } => {
  const classes = useStyles()

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 120,
      flex: 1,
      hide: true,
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: 'fullData', // used only to track full object for selected item
      hide: true,
      filterable: false,
    },
    {
      field: 'specialty',
      headerName: 'Provider',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      hide: false,
      sortable: true,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        let isRead = params.row?.fullData?.caseData?.isRead
        const isReadByOther = params.row?.fullData?.caseData?.isReadByOther
        const isDeleted = params.row?.fullData?.caseData?.isDeleted
        const createdById = params.row?.fullData?.caseData?.createdById
        if (userdata.userType === 'Admin') {
          isRead = true
        } else if (userdata.userType !== 'PCP' && isDeleted === false) {
          if (
            userdata.userType === 'PCPSUPERUSER' &&
            userdata.id == createdById
          ) {
            isRead = true
          } else if (isReadByOther === null || !isReadByOther?.length) {
            isRead = false
          } else if (isReadByOther.length) {
            isRead = isReadByOther.includes(userdata.id) ? true : false
          }
        }
        if (params.row?.fullData?.draftData) {
          return (
            <strong style={{ display: 'flex', alignItems: 'center' }}>
              <CircularComponent
                size={12}
                backgroundColor={'#ff6600'}
                fontSize={2}
                lineHeight={1}
                text={''}
              />
              <div style={{ color: '#ff6600', marginLeft: '8px' }}>Draft</div>
            </strong>
          )
        } else if (
          !params.row?.fullData?.draftData &&
          isRead !== undefined &&
          isRead === false
        ) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecordIcon
                style={{ color: '#13c1cf' }}
                fontSize="small"
              />
              <strong style={{ marginLeft: '8px' }}>{params.value}</strong>
            </div>
          )
        } else {
          return params.value
        }
      },
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      hide: false,
      sortable: true,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        let isRead = params.row?.fullData?.caseData?.isRead
        const isReadByOther = params.row?.fullData?.caseData?.isReadByOther
        const isDeleted = params.row?.fullData?.caseData?.isDeleted
        const createdById = params.row?.fullData?.caseData?.createdById
        if (userdata.userType === 'Admin') {
          isRead = true
        } else if (userdata.userType !== 'PCP' && isDeleted === false) {
          if (
            userdata.userType === 'PCPSUPERUSER' &&
            userdata.id == createdById
          ) {
            isRead = true
          } else if (isReadByOther === null || !isReadByOther.length) {
            isRead = false
          } else if (isReadByOther.length) {
            isRead = isReadByOther.includes(userdata.id) ? true : false
          }
        }
        if (
          !params.row?.fullData?.draftData &&
          isRead !== undefined &&
          isRead === false
        ) {
          return <strong>{params.value}</strong>
        } else {
          return params.value
        }
      },
    },
    {
      field: 'question',
      headerName: 'Question',
      disableColumnMenu: true,
      flex: 1,
      width: 200,
      hide: columnsToHide.includes(HideableDataGridColumns.question),
      sortable: false,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        let isRead = params.row?.fullData?.caseData?.isRead
        const isReadByOther = params.row?.fullData?.caseData?.isReadByOther
        const isDeleted = params.row?.fullData?.caseData?.isDeleted
        const createdById = params.row?.fullData?.caseData?.createdById
        if (userdata.userType === 'Admin') {
          isRead = true
        } else if (userdata.userType !== 'PCP' && isDeleted === false) {
          if (
            userdata.userType === 'PCPSUPERUSER' &&
            userdata.id == createdById
          ) {
            isRead = true
          } else if (isReadByOther === null || !isReadByOther.length) {
            isRead = false
          } else if (isReadByOther.length) {
            isRead = isReadByOther.includes(userdata.id) ? true : false
          }
        }
        if (
          !params.row?.fullData?.draftData &&
          isRead !== undefined &&
          isRead === false
        ) {
          return <strong data-selector="row">{params.value}</strong>
        } else {
          return <span data-selector="row">{params.value}</span>
        }
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Date',
      type: 'date',
      disableColumnMenu: true,
      flex: 1,
      width: 120,
      hide: columnsToHide.includes(HideableDataGridColumns.updatedAt),
      sortable: true,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        let updatedAt = params.value
        const date = new Date(updatedAt as any)
        if (isDateValid(date)) {
          updatedAt = convertTimeStampZToFormatDate(date)
        } else {
          updatedAt = convertTimeStampToFormatDate(updatedAt as string)
        }
        let isRead = params.row?.fullData?.caseData?.isRead
        const isReadByOther = params.row?.fullData?.caseData?.isReadByOther
        const isDeleted = params.row?.fullData?.caseData?.isDeleted
        const createdById = params.row?.fullData?.caseData?.createdById
        if (userdata.userType === 'Admin') {
          isRead = true
        } else if (userdata.userType !== 'PCP' && isDeleted === false) {
          if (
            userdata.userType === 'PCPSUPERUSER' &&
            userdata.id == createdById
          ) {
            isRead = true
          } else if (isReadByOther === null || !isReadByOther?.length) {
            isRead = false
          } else if (isReadByOther.length) {
            isRead = isReadByOther.includes(userdata.id) ? true : false
          }
        }
        if (
          !params.row?.fullData?.draftData &&
          isRead !== undefined &&
          isRead === false
        ) {
          return <strong>{updatedAt}</strong>
        } else {
          return updatedAt
        }
      },
    },
    {
      field: 'claimStatus',
      headerName: 'Claim Status',
      disableColumnMenu: true,
      flex: 1,
      width: 120,
      hide: columnsToHide.includes(HideableDataGridColumns.claimStatus),
      sortable: true,
      align: 'left',
      renderCell: (params: GridRenderCellParams) => {
        let isRead = params.row?.fullData?.caseData?.isRead
        const isReadByOther = params.row?.fullData?.caseData?.isReadByOther
        const isDeleted = params.row?.fullData?.caseData?.isDeleted
        const createdById = params.row?.fullData?.caseData?.createdById
        if (userdata.userType === 'Admin') {
          isRead = true
        } else if (userdata.userType !== 'PCP' && isDeleted === false) {
          if (
            userdata.userType === 'PCPSUPERUSER' &&
            userdata.id == createdById
          ) {
            isRead = true
          } else if (isReadByOther === null || !isReadByOther?.length) {
            isRead = false
          } else if (isReadByOther.length) {
            isRead = isReadByOther.includes(userdata.id) ? true : false
          }
        }
        if (
          !params.row?.fullData?.draftData &&
          isRead !== undefined &&
          isRead === false
        ) {
          return <strong>{params.value}</strong>
        } else {
          return params.value
        }
      },
    },
    {
      field: 'specialistId',
      headerName: 'Actions',
      disableColumnMenu: true,
      headerClassName:
        userdata.userType === 'Admin' ? '' : classes.visibilityHidden,
      width: userdata.userType === 'Admin' ? 110 : 80,
      hide: false,
      sortable: true,
      filterable: false,
      align: 'center',
      renderCell: renderActionsCell,
    },
  ]
  return { columns }
}

export default useDataGridColumns
